import * as firebase from "firebase/app";
import React from "react";
import * as Icon from "react-feather";
import {
  Col, ListGroup,
  ListGroupItem, Row
} from "reactstrap";
import Spinner from "../../components/@vuexy/spinner/Loading-spinner";
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// const defaultLayoutPluginInstance = defaultLayoutPlugin();

class Documetmodal extends React.Component {
  state = {
    currentlyselected: 0,
    currentdocument: null,
    spinner: true,
    pageNumber: 1,
    numPages: null
  };
  componentDidMount() {
    console.log("In documentmodal");
    console.log(this.props.data);

    // console.log(this.props.data[0].url);
    this.setState({
      currentdocument: this.props.data && this.props.data.length > 0 ? this.props.data[0].url : ""
    })
    this.props.onloadcall();
  }
  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({
      numPages: numPages
    })
    // setNumPages(numPages);
  }
  render() {
    return (
      <React.Fragment>
        {this.props.data && this.props.data.length === 0 ?
          <h2 style={{ textAlign: "center" }}>No Document Found</h2>
          :
          <Row style={{ height: "100%" }}>

            <Col md="8" sm="8"
              style={{
                height: "100%", width: "100%"
              }}
            >
              {this.state.spinner ?
                < Spinner /> : null}
              {this.state.currentdocument ?
                // <div
                //   style={{
                //     width: "100%",
                //     height: "100%"
                //   }}
                // >
                // <Viewer
                //   fileUrl={"https://firebasestorage.googleapis.com/v0/b/innerve2020.appspot.com/o/eventdata" + encodeURIComponent(this.state.currentdocument) + "?alt=media"}
                //   // defaultScale={SpecialZoomLevel.PageFit}
                //   plugins={[
                //     // Register plugins
                //     defaultLayoutPluginInstance,

                //   ]}
                // /> : null}


                <iframe title={this.state.currentlyselected.name} onLoad={() => {
                  this.setState({ spinner: false })
                }} src={"https://firebasestorage.googleapis.com/v0/b/innerve2020.appspot.com/o/eventdata" + encodeURIComponent(this.state.currentdocument) + "?alt=media"} height="100%" width="100%" /> : null}
            </Col>
            <Col md="4" sm="4" style={{ overflowY: "auto", height: "100%" }} >

              <ListGroup>
                {/* <h4 className="mb-1 text-white" >
                Heading 1
              </h4> */}
                {this.props.data && this.props.data.length > 0 && this.props.data.map((d, i) => {
                  return (
                    <ListGroupItem active={i === this.state.currentlyselected ? true : false} key={i} onClick={() => {
                      console.log("Clicked");
                      this.setState({
                        currentlyselected: i,
                        currentdocument: d.url,
                        spinner: true
                      })
                    }}>
                      <div className="d-flex justify-content-between w-100">
                        <h5 className="mb-1" style={{ color: i === this.state.currentlyselected ? "#fff" : null }}>
                          {d.name}
                        </h5>
                        <a href={this.state.currentdocument} target="_blank" rel="noreferrer" download><Icon.Download style={{ cursor: "pointer", color: i === this.state.currentlyselected ? "#fff" : null }} onClick={() => {
                          console.log("Downloading");
                          var newPostKey = firebase.database().ref().child('logs').push().key;
                          firebase.database().ref().child('logs').child(newPostKey).set({
                            email: firebase.auth().currentUser.email,
                            type: "File download",
                            message: d.name,
                            time: new Date().toString()
                          })
                        }} /></a>

                        {/* <Icon.Download style={{ cursor: "pointer" }} onClick={() => {
                          console.log("Downloading");
                        }} /> */}
                        {/* <small>Download</small> */}
                      </div>
                    </ListGroupItem>
                  )
                })}

                {/* <ListGroupItem >
                <div className="d-flex justify-content-between w-100">
                  <h5 className="mb-1 text-white">
                    Document 2
              </h5>
                  <small>3 days ago</small>
                </div>
                <p className="mb-1">
                  Donec id elit non mi porta gravida at eget metus. Maecenas
                  sed diam eget risus varius blandit.
            </p>
                <small>Donec id elit non mi porta.</small>
              </ListGroupItem> */}

              </ListGroup>
            </Col>
          </Row>}
      </React.Fragment >
    )
  }
}

export default Documetmodal