import classnames from "classnames";
import React, { Component } from "react";
import AddToCalendar from 'react-add-to-calendar';
import DataTable from "react-data-table-component";
import { Search } from "react-feather";
import ReactPlayer from 'react-player';
// import test from './test.mp4';
// import Draggable from 'react-draggable'; // The default
// import "../../../assets/scss/components/customizer.scss"
import { connect } from "react-redux";
import { Button, Input, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Spinner from "../../components/@vuexy/spinner/Loading-spinner";
import {
  changeAgendaVideo
} from "../../redux/actions/customizer/index";



// import 'react-add-to-calendar/dist/react-add-to-calendar.css'

class Agendatypelist extends Component {
  state = {
    modal: false,
    isloading: false,
    videoloading: true,
    showagenda: false,
    currentvideo: null,
    modaltitle: "",
    active: "day1",
    // value: "",
    videoname: "",
    currentdata: [],
    filteredData: [],
    value: "",
    paginationPageSize: 20,
    currenPageSize: "",
    getPageSize: "",
  };
  handleFilter = (e, currentdata) => {
    let value = e.target.value
    let data = currentdata
    let filteredData = this.state.filteredData
    this.setState({ value })

    if (value.length) {
      filteredData = data.filter(item => {
        return item.title.toLowerCase().includes(value.toLowerCase())
        // let startsWithCondition =
        //   item.title.toLowerCase().startsWith(value.toLowerCase())
        // let includesCondition =
        //   item.title.toLowerCase().includes(value.toLowerCase()) ||
        //   item.date.toLowerCase().includes(value.toLowerCase()) ||
        //   item.email.toLowerCase().includes(value.toLowerCase()) ||
        //   item.revenue.toLowerCase().includes(value.toLowerCase()) ||
        //   item.status.toLowerCase().includes(value.toLowerCase())

        // if (startsWithCondition) {
        //   return startsWithCondition
        // } else if (!startsWithCondition && includesCondition) {
        //   return includesCondition
        // } else return null
      })
      this.setState({ filteredData })
    }
  }
  handleClickOutside() {
    if (this.state.pollcustomizer) {
      this.setState({
        pollcustomizer: false
      })
      // this.props.handleCustomizer(!this.props.customizerState);
    }
  }


  toggletab = (tab, agendadata, active) => {
    if (active !== tab) {
      this.setState({ active: tab, currentdata: agendadata[tab].data, value: "" })
    }
  }
  componentDidMount() {
    this.props.onloadcall();
    // console.log("Checking for agendata");
    // console.log(this.props.agendadata);
    // if (this.props.agendadata) {
    //   this.setState({
    //     isloading: false,
    //     currentdata: this.props.agendadata.data[this.props.agendadata.active].data,
    //     active: this.props.agendadata.active,
    //     agendadata: this.props.agendadata.data
    //   })
    // } else {
    //   firebase.database().ref("games").once("value", (snap) => {
    //     console.log(snap.val());
    //     console.log(snap.val().agenda[snap.val().agendaday].data);
    //     this.setState({
    //       isloading: false,
    //       currentdata: snap.val().agenda[snap.val().agendaday].data,
    //       active: snap.val().agendaday,
    //       agendadata: snap.val().agenda
    //     })
    //   })
    // }

  }
  toggleModal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
      videoloading: true
    }))
  }
  render() {
    var active = this.state.active ? this.state.active : this.props.eventdata.agenda.edata.active;
    var agendadata = this.props.eventdata.agenda.data;
    var currentdata = agendadata[active].data;

    // const dragHandlers = { onStart: this.onStart, onStop: this.onStop };
    // var data = this.state.value.length ? this.state.filteredData : this.state.currentdata;
    if (this.state.isloading) {
      return <Spinner />
    } else {

      var agendakeys = Object.keys(agendadata);
      // console.log("In showagenda");
      // console.log(currentdata);
      return (
        <div className="">
          <Modal isOpen={this.state.modal} toggle={this.toggleModal}
            className={`modal-dialog-centered modal-lg modaltest1`}
            contentClassName={"modaltest4"}
          >
            <ModalHeader toggle={this.toggleModal}>{this.state.modaltitle}</ModalHeader>
            <ModalBody style={{ overflow: "auto", height: "100%" }}>
              {this.state.videoloading ?
                < Spinner /> : null}
              <ReactPlayer className='react-player' style={{ margin: "auto" }} onReady={() => {
                this.setState({ videoloading: false })

              }} playing controls={true}
                height='100%'
                width="100%"
                url={this.state.currentvideo} />
            </ModalBody>

          </Modal>
          <Nav tabs style={{ height: "90%" }}>
            {agendakeys.map((key, i) => {
              return (
                <NavItem key={i} style={{ borderRight: "1px solid #000" }}>
                  <NavLink
                    style={{ backgroundColor: active === key ? null : "#babfc761", }}
                    className={classnames({
                      active: active === key
                    })}
                    onClick={() => {
                      this.toggletab(key, agendadata, active)
                    }}
                  >
                    {agendadata[key].name}
                  </NavLink>
                </NavItem>
              )
            })}
            <TabContent style={{ marginTop: 10, width: "100%", height: "90%" }} activeTab={active}>
              {agendakeys.map((key, i) => {

                return (
                  <TabPane key={i} tabId={key} style={{ height: "100%", width: "100%" }}>
                    {currentdata ?
                      <div className="d-flex flex-wrap justify-content-between">

                        <div className="position-relative has-icon-left mb-1" style={{ width: "100%" }}>
                          <Input value={this.state.value} onChange={e => this.handleFilter(e, currentdata)} />
                          <div className="form-control-position">
                            <Search size="15" />
                          </div>
                        </div>
                      </div> : null}

                    <DataTable
                      // title={data.name + " Videos"}
                      responsive
                      pointerOnHover
                      noHeader
                      noTableHead
                      subHeader={false}
                      columns={[
                        {
                          name: 'Title',
                          width: '70%',
                          wrap: true,
                          // allowOverflow:true,
                          sortable: true,
                          cell: row =>
                            this.props.agendadata ? <div><div style={{ fontWeight: "bold", wordWrap: "break-word" }}>{row.title}{row.des ? <p style={{ marginTop: 3, marginBottom: 3 }} dangerouslySetInnerHTML={{ __html: row.des }}></p> : null}<p style={{ marginTop: 3, marginBottom: 3 }} >{row.time}</p></div> </div> : <div><div style={{ paddingTop: 10, paddingBottom: 10 }}><div style={{ fontWeight: "bold", marginTop: 3, marginBottom: 15, color: "#000", fontSize: 15 }}>{row.time}</div><div style={{ fontWeight: "bold", color: "#000", marginTop: 0, fontSize: 15 }}>{row.title}</div>{row.des ? <p style={{ marginTop: 5, marginBottom: 3 }} dangerouslySetInnerHTML={{ __html: row.des }}></p> : null}<p style={{ marginTop: 3, marginBottom: 3 }} ></p></div> </div>,
                        },
                        {
                          name: "Actions",
                          // sortable: true,
                          width: "30%",
                          right: true,
                          cell: row =>
                            (
                              <div className="data-list-action" style={{ display: "flex", justifyContent: "center", alignItems: "center",flexDirection:"column" }}>
                                {(row.video && row.video.length > 0) || (row.redirect && row.redirect.length > 0) ?
                                  <Button.Ripple color="primary" className="round" onClick={() => {
                                    // props.this.setState({
                                    //   showagenda: false,
                                    //   videolink: props.row.video,
                                    //   videoname: props.row.name,
                                    //   // modaltype: props.row.type,

                                    // })
                                    if (row.video && row.video.length > 0) {
                                      this.props.redirecttopage(row.redirect);
                                      this.props.changeAgendaVideo(row.video);
                                      // this.setState({
                                      //   modal: true,
                                      //   currentvideo: row.video,
                                      //   modaltitle: row.title
                                      // })
                                    } else {
                                      console.log(row);
                                      if(row.newtab){
                                        window.open(row.redirect, '_blank');
                                      }else{
                                        this.props.redirecttopage(row.redirect, row.video);

                                      }
                                    }

                                  }}>{row.video && row.video.length > 0 ? "Play" : this.props.agendadata ? "Visit" : "Join"}</Button.Ripple> : null}

                                {row.caltime ?
                                  <div style={{ marginTop: 5 }}>  <AddToCalendar listItems={[
                                    { google: 'Google' },
                                    { apple: 'Apple' },
                                    { outlook: 'Outlook' },
                                    { yahoo: 'Yahoo' },

                                  ]} event={{
                                    title: row.title,
                                    description: "",
                                    location: '',
                                    startTime: row.caltime,
                                    // endTime: '2016-09-16T21:45:00-04:00'
                                  }} buttonTemplate={{ 'calendar-plus-o': 'left' }} />  </div> : null}

                              </div>
                            )

                        },
                      ]}
                      data={this.state.value.length ? this.state.filteredData : currentdata}
                    />



                  </TabPane>
                )
              })}
            </TabContent>
          </Nav>
        </div>
      )
    }
  }
}
const mapStateToProps = state => {
  return {
    eventdata: state.auth.login.eventdata
    // customizerstate: state.customizer.customizer.customizerState,
    // pollcustomizer: state.customizer.customizer.pollcustomizer
  }
}
export default connect(mapStateToProps, {
  changeAgendaVideo
})(Agendatypelist)
