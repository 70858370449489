// Initialize Firebase

import * as firebase from "firebase/app"
import 'firebase/analytics'
import 'firebase/functions'

// production code for console.log
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  console.log("Development");
} else {
  console.log("Production");
  console.log = () => {};

}
const config =  {
  apiKey: "AIzaSyArOer-del36q5I0vMGR52HjuhqwZk9vZ4",
  authDomain: "innerve2020.firebaseapp.com",
  databaseURL: "https://innerve2020-default-rtdb.firebaseio.com",
  projectId: "innerve2020",
  storageBucket: "innerve2020.appspot.com",
  messagingSenderId: "90940057734",
  appId: "1:90940057734:web:55f8aba2e8574a77600907",
  measurementId: "G-0KPLGZ6GFR"
};
firebase.initializeApp(config)
firebase.analytics();