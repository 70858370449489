import themeConfig from "../../../configs/themeConfig"

const customizerReducer = (state = themeConfig, action) => {
  switch (action.type) {
    case "CHANGE_MODE":
      return { ...state, theme: action.mode }
    case "CUSTOMIZER_STATE":
      return { ...state, customizerState: action.value }
    case "DISABLE_CUSTOMIZER":
      return { ...state, disableCustomizer: action.value }
    case "POLL_CUSTOMIZER_STATE":
      return { ...state, pollcustomizer: action.value }
    case "OFF_CUSTOMIZER":
      return { ...state, pollcustomizer: action.value, customizerState: action.value }
    case "CHANGE_AGENDA_VIDEO":
      return { ...state, agendavideo: action.value }
    case "HOME_TOUR":
      return {
        ...state, hometour: {
          ...state.hometour,
          [action.value]: true
        }
      }
    case "HOME_TOUR_FALSE":
      return {
        ...state, hometour: {
          ...state.hometour,
          [action.value]: false
        }
      }
    case "HOME_VIDEO":
      return { ...state, homevideo: action.value }
    case "COLLAPSE_SIDEBAR":
      return { ...state, sidebarCollapsed: action.value }
    case "CHANGE_NAVBAR_COLOR":
      return { ...state, navbarColor: action.color }
    case "CHANGE_NAVBAR_TYPE":
      return { ...state, navbarType: action.style }
    case "CHANGE_FOOTER_TYPE":
      return { ...state, footerType: action.style }
    case "CHANGE_MENU_COLOR":
      return { ...state, menuTheme: action.style }
    case "HIDE_SCROLL_TO_TOP":
      return { ...state, hideScrollToTop: action.value }
    case "OLD_NOTIFICATIONS":
      return { ...state, notification: action.value }
    case "NEW_NOTIFICATION":

      return {
        ...state, notification: {
          ...state.notification,
          [action.value.key]: action.value.data
        }
      }
    case "OLD_MEETINGS":
      return { ...state, meetings: action.value }
    case "NEW_MEETINGS":

      return {
        ...state, meetings: {
          ...state.meetings,
          [action.value.key]: action.value.data
        }
      }
    case "TOGGLE_NOTIMODAL":
      // console.log(state);
      // console.log(action);
      return {
        ...state, notimodal: action.data.notimodal, notidata: action.data.notidata
      }
    case "TOGGLE_BCARDMODAL":
      // console.log(state);
      // console.log(action);
      return {
        ...state, bcardmodal: action.data.bcardmodal, bcarddata: action.data.bcarddata
      }
    case "TOGGLE_MEETINGMODAL":
      // console.log(state);
      // console.log(action);
      return {
        ...state, mcardmodal: action.data.mcardmodal, mcarddata: action.data.mcarddata
      }
    default:
      return state
  }
}

export default customizerReducer
