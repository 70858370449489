import * as firebase from "firebase/app"
import "firebase/auth"
import "firebase/database"
// import "firebase/firestore"
import "firebase/storage"
import { toast, Flip } from "react-toastify"
import _ from "lodash";

import { history } from "../../../history"
// import { JsonWebTokenError } from "jsonwebtoken"
// import { config } from "../../../authServices/firebase/firebaseConfig"
// console.log("hello5");
// // Init firebase if not already initialized
// if (!firebase.apps.length) {
//   firebase.initializeApp(config)
// }
console.log("In loginactions");
let firebaseAuth = firebase.auth()
// var initialchat = false;
var onlinelistenerchange;
var onlinelisteneradded
export const publicdataofuser = (uid) => {
  return new Promise((resolve, reject) => {
    var id = new Date().getTime();
    console.time(id);
    firebase.firestore().collection("pub_user").doc(uid).get().then((snap) => {
      console.timeEnd(id);
      resolve(snap.data());
    }).catch(err => {
      reject(err);
      console.log(err);
    })
  });
}
export const getleaderboard = (user) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      firebase.database().ref('pub_user').orderByChild('total').once("value", function (snap) {
        var stat = [];
        snap.forEach(function (child) {
          // console.log("Testing1233");
          // console.log(child);
          // console.log(child.key); // 1498230632003..., 1498230632000...
          if (child.val().team !== "team2") {
            stat.push({ id: child.key, ...child.val() })

          } else {
            // console.log("Testing123");
            // console.log(child);
          }
        });
        resolve({ e: true, data: stat.reverse() });
      }, error => {
        resolve({ e: false });
        console.log("Error getting document:", error);
      });
    });
  }
}
export const getleaderboard2 = (user) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      firebase.database().ref('pub_user').orderByChild('total').once("value", function (snap) {
        var stat = [];
        snap.forEach(function (child) {
          // console.log(child.key); // 1498230632003..., 1498230632000...
          if (child.team !== "team1") {
            stat.push({ id: child.key, ...child.val(), total: child.val().hunt ? child.val().hunt : 0 })

          }
        });
        resolve({ e: true, data: _.sortBy(stat, ['total']).reverse() });
      }, error => {
        resolve({ e: false });
        console.log("Error getting document:", error);
      });
    });
  }
}
// function getuserlist(list, callback) {
//   console.time("tet1")
//   let userRefs = list.map(id => {
//     return firebase.firestore().collection('pub_user').doc(id).get();
//   });
//   Promise.all(userRefs)
//     .then(docs => {
//       console.timeEnd("tet1");
//       var users = {};
//       docs.forEach(doc => {
//         users[doc.id] = doc.data();
//       });
//       callback({ error: 0, data: users });
//     })
//     .catch(error => callback({ error: 1, err: error }))
// }
export const togglenotimodal = data => {
  return dispatch => {
    dispatch({ type: "TOGGLE_NOTIMODAL", data: data })
  }
}
export const togglebcardmodal = data => {
  return dispatch => {
    dispatch({ type: "TOGGLE_BCARDMODAL", data: data })
  }
}
export const togglemeetingmodal = data => {
  return dispatch => {
    dispatch({ type: "TOGGLE_MEETINGMODAL", data: data })
  }
}
export const startmeetingslistner = user => {
  return dispatch => {
    console.log("MEETINGS dispatch");
    var ref = firebase.database().ref("meetings/" + firebase.auth().currentUser.uid);
    var initialLoadDone = false;
    ref.on("child_added", (snap) => {

      if (initialLoadDone) {


        dispatch({ type: "NEW_MEETINGS", value: { key: snap.key, data: snap.val() } });
        console.log("New Meeting recieved");
        console.log(snap.val());
        if (snap.val().genid !== firebase.auth().currentUser.uid) {

          toast.success("Meeting Request Received from " + snap.val().genname, { transition: Flip });
        }

        // dispatch({
        //   type: "TOGGLE_NOTIMODAL", data: {
        //     notimodal: true,
        //     notidata: {
        //       title: snap.val().title,
        //       message: snap.val().msg,
        //       imgurl: snap.val().imgurl,
        //       link: snap.val().link
        //     }
        //   }
        // })
      }
    })
    ref.on("child_changed", (snap) => {

      if (initialLoadDone) {


        dispatch({ type: "NEW_MEETINGS", value: { key: snap.key, data: snap.val() } });
        console.log(" Meeting update");
        console.log(snap.val());
        // if(snap.val().genid!==firebase.auth().currentUser.uid){

        //   toast.success("Meeting Request Recived from " + snap.val().genname, { transition: Flip });
        // }

        // dispatch({
        //   type: "TOGGLE_NOTIMODAL", data: {
        //     notimodal: true,
        //     notidata: {
        //       title: snap.val().title,
        //       message: snap.val().msg,
        //       imgurl: snap.val().imgurl,
        //       link: snap.val().link
        //     }
        //   }
        // })
      }
    })
    ref.once("value", (snap) => {
      console.log("Old Notification");
      console.log(snap.val());
      initialLoadDone = true;
      if (snap.exists()) {

        dispatch({ type: "OLD_MEETINGS", value: snap.val() })


      } else {
        dispatch({ type: "OLD_MEETINGS", value: {} })
      }
    });
  }
}
export const startnotificationlistner = user => {
  return dispatch => {
    console.log("NOtification dispatch");
    var ref = firebase.database().ref("noti");
    var initialLoadDone = false;
    ref.on("child_added", (snap) => {

      if (initialLoadDone) {


        dispatch({ type: "NEW_NOTIFICATION", value: { key: snap.key, data: snap.val() } })
        dispatch({
          type: "TOGGLE_NOTIMODAL", data: {
            notimodal: true,
            notidata: {
              title: snap.val().title,
              message: snap.val().msg,
              imgurl: snap.val().imgurl,
              link: snap.val().link
            }
          }
        })
      }
    })
    ref.once("value", (snap) => {
      console.log("Old Notification");
      console.log(snap.val());
      initialLoadDone = true;
      if (snap.exists()) {
        dispatch({ type: "OLD_NOTIFICATIONS", value: snap.val() })
      } else {
        dispatch({ type: "OLD_NOTIFICATIONS", value: {} })
      }
    });
  }
}
export const startconstatus = (user) => {
  return dispatch => {

    firebase.database().ref("pub_user/" + user.uid + "/status").once("value", (snapp) => {
      var status;
      if (snapp.exists()) {
        status = "busy";
      } else {
        status = true;
      }
      firebase.database().ref("cstat/" + user.uid).set(status)

      firebase.database().ref("cstat/" + user.uid).onDisconnect() // Setting up the onDisconnect hook
        .set(false);
      var initialloaddone = false;

      onlinelistenerchange = firebase.database().ref("cstat").on("child_changed", (snap) => {
        if (initialloaddone) {
          if (snap.key === user.uid) {
            if (!snap.val()) {
              setTimeout(() => {
                firebase.database().ref("cstat/" + user.uid).set(status)
              }, 1000);
            }
          }
          console.log("Got update con status");
          console.log(snap.val());
          dispatch({ type: "UPDATE_CON_STAT", data: snap.val(), key: snap.key })
        }
      })
      onlinelisteneradded = firebase.database().ref("cstat").on("child_added", (snap) => {
        if (initialloaddone) {
          console.log("Got update con status");
          console.log(snap.val());
          dispatch({ type: "UPDATE_CON_STAT", data: snap.val(), key: snap.key })
        }
      })
      firebase.database().ref("cstat").once("value", (snap) => {
        console.log("Got Intial con status");
        initialloaddone = true;
        console.log(snap.val());
        dispatch({ type: "INTIIAL_CON_STAT", data: snap.val() })
      })
    })

    // firebase
    //   .database()
    //   .ref(".info/connected")
    //   .on("value", function (snap) {
    //     if (snap.val()) {
    //       firebase.database().ref("cstat/" + user.uid).set(true)
    //     } else {
    //       firebase.database().ref("cstat/" + user.uid).set(false)
    //     }
    //   });
  }
}
// function startfirebaselistner(uid, dispatch) {
//   firebase.firestore().collection("users").doc(uid).collection("chatd").onSnapshot(snapshot => {
//     var intialchatdata = [];
//     snapshot.docChanges().forEach(function (change) {
//       if (change.type === "added") {
//         if (!initialchat) {
//           intialchatdata.push(change.doc.id);
//         }
//         console.log("New city: ", change.doc.data());
//       }
//       if (change.type === "modified") {
//         console.log("Modified city: ", change.doc.data());
//       }
//       if (change.type === "removed") {
//         console.log("Removed city: ", change.doc.data());
//       }
//     });
//     if (!initialchat) {
//       initialchat = true;
//       console.log(intialchatdata);
//       console.log("Getting user ids");
//     }
//   })
//   // firebase.database().ref("chatd/" + uid).once('value', function (snapshot) {
//   //   console.log(snapshot.val());
//   //   getuserlist(Object.keys(snapshot.val()), (data) => {
//   //     console.log("Getting user list");
//   //     console.log(data)
//   //   })
//   //   firebase.database().ref("chatd/" + uid).on('child_changed', function (snapchange) {
//   //     console.log("Child Changed");
//   //     console.log(snapchange.key);
//   //     console.log(snapchange.val());
//   //   });
//   //   firebase.database().ref("chatd/" + uid).on('child_added', function (snapadded) {
//   //     console.log("Child Added");
//   //     console.log(snapadded)
//   //     publicdataofuser(snapadded.key).then(data => {
//   //       console.log(data);
//   //     }).catch(err => {
//   //       console.log(err);
//   //     })
//   //     console.log(snapadded.key);
//   //     console.log(snapadded.val());
//   //   });
//   //   dispatch({ type: "CHANGE_ROLE", userRole: "admin" });
//   //   // updateStarCount(postElement, snapshot.val());
//   // });

// }
export const profilesavedone = (finald) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      console.log("In profile svae done");
      console.log(finald);
      var finaldata = { publicdata: finald.publicdata };
      resolve({ e: 0, type: 0 });
      dispatch({ type: "FIREBASE_DATA", data: finaldata })
      dispatch({ type: "CHANGE_ROLE", userRole: "admin" });
    })
  }
}
export const updatepublicdata = (data) => {
  return dispatch => {
    dispatch({ type: "UPDATE_FIREBASE_DATA", data: data })
  }
}
function createpresence(uid) {
  console.log("Creating user presence");
  var myConnectionsRef = firebase.database().ref('pub_user/' + uid + '/connections');
  var liveuser = firebase.database().ref('liveuser/count');
  var connectedRef = firebase.database().ref('.info/connected');
  connectedRef.on('value', function (snap) {
    if (snap.val() === true) {
      var con = myConnectionsRef.push();
      con.onDisconnect().remove();
      liveuser.onDisconnect().set(firebase.database.ServerValue.increment(-1))
      con.set(true);
      liveuser.set(firebase.database.ServerValue.increment(1))
    }
  });
}
export const getpublicdataofuser = (user) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      firebase.database().ref("pub_user/" + user.uid).once("value", (snap) => {

        firebase.database().ref("games/data").once("value", (snapmain) => {
          console.log("Getting eventdata");
          console.log(snapmain.val());

          if (snapmain.val().settings.chat) {
            dispatch({ type: "DISABLE_CUSTOMIZER", value: false })
          }
          console.log("in getpublicdataofuser");
          if (snap.exists()) {
            createpresence(user.uid);
            console.log("got initial public data");
            console.log(snap.val());
            var finaldata = { publicdata: { uid: user.uid, ...snap.val() } };
            resolve({ e: 0, type: 0 });
            dispatch({ type: "FIREBASE_DATA", data: finaldata })
            dispatch({ type: "EVENT_DATA", data: snapmain.val() })
            dispatch({ type: "CHANGE_ROLE", userRole: "admin" });
            dispatch({
              type: "LOGIN_WITH_EMAIL",
              payload: {
                email: user.email,
                uid: user.uid,
                isSignedIn: true,
                loggedInWith: "firebase"
              }
            });
          } else {
            dispatch({ type: "EVENT_DATA", data: snapmain.val() })

            resolve({ e: 0, type: 1 });
            dispatch({
              type: "LOGIN_WITH_EMAIL",
              payload: {
                email: user.email,
                uid: user.uid,
                isSignedIn: true,
                loggedInWith: "firebase"
              }
            });
          }
        })

      }).catch(err => {
        console.log(err);
      })
    })
  }
}
// const initAuth0 = new auth0.WebAuth(configAuth)
export const initialuserfound = (user) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      console.time("firestoretest2");
      console.log("In initialuserfound1");
      let userRefs = [];
      userRefs.push(firebase.firestore().collection("events").doc("virtual").get());
      userRefs.push(firebase.firestore().collection("users").doc(user.uid).get());
      userRefs.push(firebase.firestore().collection("users").doc(user.uid).collection("private").doc("data").get());
      Promise.all(userRefs)
        .then(docs => {
          console.timeEnd("firestoretest2");
          var finaldata = { eventdata: docs[0].data(), publicdata: docs[1].data(), privatedata: docs[2].data() };
          resolve("done");
          dispatch({ type: "FIREBASE_DATA", data: finaldata })
          dispatch({ type: "CHANGE_ROLE", userRole: "admin" });
          dispatch({
            type: "LOGIN_WITH_EMAIL",
            payload: {
              email: user.email,
              name: user.displayName,
              uid: user.uid,
              isSignedIn: true,
              loggedInWith: "firebase"
            }
          });
        })
        .catch(error => {
          console.log("THere was an error");
          console.log(error);
        })
    });
  }
}

export const resetloginpassword = (email) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      firebaseAuth.sendPasswordResetEmail(email).then(function () {
        // Email sent.
        resolve({ e: true });

      }).catch(function (error) {
        // An error happened.
        resolve({ e: false, d: error });
      });
    });
  }

}
export const submitLoginWithFireBase = (email, password, remember) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      // let userEmail = null,
      //   loggedIn = false
      firebaseAuth
        .signInWithEmailAndPassword(email, password)
        .then(result => {
          // result.user.updateProfile({
          //   displayName: "Admin"
          // })
          resolve({ e: false, d: "done" });
        })
        .catch(error => {
          resolve({ e: true, d: error });
          console.log(error)
        })
    });
  }

}

export const loginWithFB = () => {
  return dispatch => {
    let provider = new firebase.auth.FacebookAuthProvider()
    provider.setCustomParameters({
      display: "popup"
    })
    firebaseAuth
      .signInWithPopup(provider)
      .then(result => {
        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        let token = result.credential.accessToken,
          // The signed-in user info.
          user = result.user.email
        dispatch({
          type: "LOGIN_WITH_FB",
          payload: {
            user,
            uid: result.user.uid,
            token,
            loggedInWith: "firebase"
          }
        })
        // if (user) history.push("/")
      })
      .catch(error => {
        console.log(error)
      })
  }
}

export const loginWithTwitter = () => {
  return dispatch => {
    let provider = new firebase.auth.TwitterAuthProvider()
    firebaseAuth
      .signInWithPopup(provider)
      .then(function (result) {
        let token = result.credential.accessToken,
          user = result.user.email,
          name = result.user.displayName,
          photoUrl = result.user.photoURL
        dispatch({
          type: "LOGIN_WITH_TWITTER",
          payload: {
            user,
            name,
            photoUrl,
            token,
            loggedInWith: "firebase"
          }
        })
        history.push("/")
      })
      .catch(function (error) {
        console.log(error)
      })
  }
}

export const loginWithGoogle = () => {
  return dispatch => {
    let provider = new firebase.auth.GoogleAuthProvider()
    firebaseAuth
      .signInWithPopup(provider)
      .then(function (result) {
        let token = result.credential.accessToken,
          user = result.user.email,
          name = result.user.displayName,
          photoUrl = result.user.photoURL
        dispatch({
          type: "LOGIN_WITH_GOOGLE",
          payload: {
            email: user,
            name: name,
            uid: result.user.uid,
            photoUrl,
            token,
            loggedInWith: "firebase"
          }
        })
        // history.push("/")
      })
      .catch(function (error) {
        console.log(error)
      })
  }
}

export const loginWithGithub = () => {
  return dispatch => {
    let provider = new firebase.auth.GithubAuthProvider()
    firebaseAuth
      .signInWithPopup(provider)
      .then(function (result) {
        let token = result.credential.accessToken,
          user = result.user.email,
          name = result.additionalUserInfo.username,
          photoUrl = result.user.photoURL

        dispatch({
          type: "LOGIN_WITH_GITHUB",
          payload: {
            user,
            name,
            photoUrl,
            token,
            loggedInWith: "firebase"
          }
        })
        history.push("/")
      })
      .catch(function (error) {
        console.log(error)
      })
  }
}



export const logoutWithFirebase = user => {
  return dispatch => {
    console.log("Signoutt");
    firebase.database().ref("cstat").off("child_added", onlinelisteneradded);
    firebase.database().ref("cstat").off("child_changed", onlinelistenerchange);
    firebase.database().ref("cstat/" + firebase.auth().currentUser.uid).set(false).then(() => {

      // firebase.database().ref("cstat")
      firebase.auth().signOut().then(function () {
        // Sign-out successful.
        console.log("Signed Out");
        localStorage.removeItem("esession");

        dispatch({ type: "CHANGE_ROLE", userRole: "" })

        dispatch({ type: "LOGOUT_WITH_FIREBASE", payload: {} })
        history.push("/pages/login")
      }, function (error) {
        // An error happened.
      });
    }).catch(err => {
      alert("There was an error please try again later")
      // firebase.auth().signOut().then(function () {
      //   // Sign-out successful.
      //   console.log("Signed Out");
      //   dispatch({ type: "CHANGE_ROLE", userRole: "" })

      //   dispatch({ type: "LOGOUT_WITH_FIREBASE", payload: {} })
      //   history.push("/pages/login")
      // }, function (error) {
      //   // An error happened.
      // });
    })

  }
}

export const changeRole = role => {
  return dispatch => dispatch({ type: "CHANGE_ROLE", userRole: role })
}
