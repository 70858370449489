import classnames from "classnames"
import * as firebase from "firebase/app"
import React from "react"
import enhanceWithClickOutside from 'react-click-outside'
// import { CustomInput } from "reactstrap"
import { X } from "react-feather"
import { connect } from "react-redux"
import { Badge } from "reactstrap"
import "../../../assets/scss/components/customizer.scss"
import { changecurrenchatuser } from "../../../redux/actions/chat/index"
// import PerfectScrollbar from "react-perfect-scrollbar"
import { ContextLayout } from "../../../utility/context/Layout"
// const chat = lazy(() => import("./views/apps/chat/Chat"))
// const chat = lazy(() => import("../../../views/apps/chat/Chat"))
import Chat from "../../../views/apps/chat/Chat"



class Customizer extends React.Component {
  state = {
    activeNavbar: this.props.activeNavbar,
    navbarType: null,
    footerType: null,
    menuTheme: null,
    collapsedSidebar: null,
    tooltip: false
  }


  componentDidMount() {
    console.log("In chat customizer")
    this.setState({
      navbarType: this.props.navbarType,
      footerType: this.props.footerType,
      menuTheme: this.props.menuTheme,
      activeMode: this.props.activeMode,
      collapseSidebar: this.props.sidebarState,
      tooltip: true
    })
  }
  handleNavbarChange = color => {
    this.props.changeNavbar(color)
    this.setState({
      activeNavbar: color
    })
  }
  handleClickOutside() {
    console.log("Outside click called");
    this.props.offallcustomizer();
  }


  render() {

    return (
      <ContextLayout.Consumer>
        {context => {
          return (
            <>
              {this.props.eventdata.settings.chat ?
                <div
                  style={{
                    zIndex: 1000
                  }}
                  className={classnames("customizer d-md-block", {
                    open: this.props.customizerState === true
                  })}
                >

                  <div
                    id="chattooltip"
                    className="customizer-toggle"
                    style={{
                      left: this.props.eventdata.settings.chatdata.left,
                      width: this.props.eventdata.settings.chatdata.width,
                      bottom: this.props.eventdata.settings.chatdata.bottom ? this.props.eventdata.settings.chatdata.bottom : "10%",
                    }}
                    onClick={() => {
                      // firebase.database().ref("audi").update({
                      //   network: firebase.database.ServerValue.increment(1)
                      // });
                      this.props.handleCustomizer(!this.props.customizerState)
                    }
                    }
                  >

                    <div>
                      <div style={{ marginTop: 5 }}>{this.props.eventdata.settings.chatdata.text}</div>
                      {this.props.customizerState || this.props.totalnonread <= 0 ? null :
                        <Badge pill color="primary" className="badge-up">
                          {this.props.totalnonread}
                        </Badge>}
                    </div>

                  </div>

                  <div className="header d-flex justify-content-between px-1 pt-1"

                  >

                    <div className="title">
                      <div className="mb-0" style={{ paddingBottom: "1.5rem" }} dangerouslySetInnerHTML={{
                        __html:
                          this.props.eventdata.settings.chatdata.top
                      }} ></div>
                      {/* <small>Customize & Preview in Real Time</small> */}
                    </div>
                    <div
                      className="close-icon cursor-pointer"
                      onClick={() => {
                        this.props.handleCustomizer(false)
                        this.props.changecurrenchatuser(null);
                      }}
                    >
                      <X size={24} />
                    </div>
                  </div>
                  {/* <hr /> */}
                  {/* <iframe title="Participant view" height="100%" width="100%" src="https://app.sli.do/event/hwtfein6"></iframe> */}

                  <Chat />
                </div> : null}
            </>
          )
        }}
      </ContextLayout.Consumer>
    )
  }
}
// collapseSidebar
const mapStateToProps = state => {
  return {
    eventdata: state.auth.login.eventdata,
    sidebarCollapsed: state.customizer.customizer.sidebarCollapsed,
    totalnonread: state.chatApp.chats.totalnonread
  }
}
// export default (connect(mapStateToProps, null)(Customizer))
export default enhanceWithClickOutside(connect(mapStateToProps, { changecurrenchatuser }, null, { forwardRef: true })(Customizer));