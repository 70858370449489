import axios from "axios";
import * as firebase from "firebase/app";
import { Flip, toast } from "react-toastify";
// import img from "../../../assets/img/user.png"
import { history } from "../../../history";

// var initialchat = false;
function getchatuserlist(list, chats, callback) {
  console.time("tet1")
  let userRefs = list.map(id => {
    return firebase.database().ref("pub_user/" + id).once("value");
  });
  Promise.all(userRefs)
    .then(docs => {
      console.timeEnd("tet1");
      var users = [];

      docs.forEach(doc => {
        console.log({ id: doc.key, ...doc.val(), chat: chats[doc.key] })
        users.push({ id: doc.key, ...doc.val(), chat: chats[doc.key] });
      });
      callback({ error: 0, data: users });
    })
    .catch(error => { console.log(error); callback({ error: 1, err: error }) })
}
export const changecurrenchatuser = (user) => {
  return dispatch => {
    console.log("changing current chat user");
    console.log(user);
    dispatch({
      type: "CURRENT_CHAT_USER",
      user: user
    })
  }
}
export const emptyroomname = () => {
  return dispatch => {
    console.log("Empty room name");
    dispatch({
      type: "VIDEO_CALL_ROOM_NAME",
      value: null
    })
  }
}
export const tooglevideocallmodal = value => {
  return dispatch => dispatch({ type: "VIDEO_CALL_MODAL", value })
}
// function videocall(data) {
//   return (dispatch) => {
//     /*dispatch more actions*/


//   }
// }
export const getChats = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      console.log("Starting firebase listner");
      console.log(firebase.auth().currentUser.uid);
      var cuid = firebase.auth().currentUser.uid;
      var ref = firebase.database().ref("chatd/" + cuid);
      var initialLoadDone = false;
      ref.on("child_changed", (changed) => {
        console.log("Firebase realtime chatd change");
        console.log(changed.key);
        console.log(changed.val());
        var data = changed.val()
        var cmessage = data.lm ? data.lm.mes : null;
        if (cmessage.startsWith("{")) {
          try {
            var finalmessage = JSON.parse(cmessage);
            if (finalmessage.msg) {
              cmessage = finalmessage.msg;
              if (finalmessage.t === 1) {
                if (data.lm.by === firebase.auth().currentUser.uid) {
                  console.log("Show video call modal for calling");
                  // toast.success("Meeting Request Sent Successfully", { transition: Flip })
                  // dispatch({
                  //   type: "VIDEO_CALL_MODAL",
                  //   value: {
                  //     modal: true,
                  //     modaldata: {
                  //       type: 0,
                  //       message: "Waiting for call to answer",
                  //       by: data.lm.by,
                  //       to: data.lm.to,
                  //       params: finalmessage.params
                  //     },
                  //   }
                  // })
                } else {
                  console.log("Show video call modal for accept and deny");
                  toast.success("Meeting Request from " + finalmessage.params, { transition: Flip })
                  // dispatch({
                  //   type: "VIDEO_CALL_MODAL",
                  //   value: {
                  //     modal: true,
                  //     modaldata: {
                  //       type: 1,
                  //       message: finalmessage,
                  //       by: data.lm.by,
                  //       to: data.lm.to,
                  //       params: finalmessage.params
                  //     },
                  //   }
                  // })
                }
              } else {
                if (finalmessage.t === 4) {
                  console.log("Starting video call");
                  console.log(finalmessage.params);
                  dispatch({
                    type: "CUSTOMIZER_STATE",
                    value: false

                    // contacts: data.data,
                    // chats: initialchats
                  })
                  dispatch({
                    type: "VIDEO_CALL_ROOM_NAME",
                    value: finalmessage.params

                    // contacts: data.data,
                    // chats: initialchats
                  })
                  history.push({
                    pathname: '/videocall'
                  }
                  );
                }
                if (finalmessage.t === 3) {
                  dispatch({
                    type: "VIDEO_CALL_MODAL",
                    value: {
                      modal: true,
                      modaldata: {
                        type: 2,
                        message: "Video call declined",

                      },
                    }

                    // contacts: data.data,
                    // chats: initialchats
                  })

                } else {
                  dispatch({
                    type: "VIDEO_CALL_MODAL",
                    value: {
                      modal: false,
                      modaldata: null
                    }

                    // contacts: data.data,
                    // chats: initialchats
                  })

                }
              }

            }
          } catch (error) {
            // console.log(error);
          }
        }

        dispatch({
          type: "UPDATE_CHAT",
          id: changed.key,
          data: changed.val(),
          // contacts: data.data,
          // chats: initialchats
        })
      })
      ref.on("child_added", (added) => {
        if (initialLoadDone) {
          console.log("Added new");
          console.log(added.val());
          firebase.database().ref("pub_user/" + added.key).once("value", snap => {
            console.log("Adding new chat user");
            console.log(snap.val());
            // videocall(snap.val())
            var data = added.val()
            var cmessage = data.lm ? data.lm.mes : null;
            if (cmessage.startsWith("{")) {
              try {
                var finalmessage = JSON.parse(cmessage);
                if (finalmessage.msg) {
                  cmessage = finalmessage.msg;
                  if (finalmessage.t === 1) {
                    if (data.lm.by === firebase.auth().currentUser.uid) {
                      console.log("Show video call modal for calling");
                      dispatch({
                        type: "VIDEO_CALL_MODAL",
                        value: {
                          modal: true,
                          modaldata: {
                            type: 0,
                            message: "Waiting for call to answer",
                            by: data.lm.by,
                            to: data.lm.to,
                            params: finalmessage.params
                          },
                        }

                        // contacts: data.data,
                        // chats: initialchats
                      })
                    } else {
                      console.log("Show video call modal for accept and deny");
                      dispatch({
                        type: "VIDEO_CALL_MODAL",
                        value: {
                          modal: true,
                          modaldata: {
                            type: 1,
                            message: finalmessage,
                            by: data.lm.by,
                            to: data.lm.to,
                            params: finalmessage.params
                          },
                        }

                        // contacts: data.data,
                        // chats: initialchats
                      })
                    }
                  } else {
                    if (finalmessage.t === 4) {
                      console.log("Starting video call");
                      console.log(finalmessage.params);
                      dispatch({
                        type: "CUSTOMIZER_STATE",
                        value: false

                        // contacts: data.data,
                        // chats: initialchats
                      })
                      dispatch({
                        type: "VIDEO_CALL_ROOM_NAME",
                        value: finalmessage.params

                        // contacts: data.data,
                        // chats: initialchats
                      })
                      history.push({
                        pathname: '/videocall'
                      }
                      );
                    }
                    if (finalmessage.t === 3) {
                      dispatch({
                        type: "VIDEO_CALL_MODAL",
                        value: {
                          modal: true,
                          modaldata: {
                            type: 2,
                            message: "Video call declined",

                          },
                        }
                      })

                    } else {
                      dispatch({
                        type: "VIDEO_CALL_MODAL",
                        value: {
                          modal: false,
                          modaldata: null
                        }
                      })

                    }
                  }

                }
              } catch (error) {
                // console.log(error);
              }
            }
            var toadddata = { id: added.key, ...snap.val(), chat: added.val() };
            console.log(toadddata);
            dispatch({
              type: "ADD_CHAT_CONTACTS",
              chats: toadddata,
              totalnonread: added.val().us
            })
          })
        }
      })
      ref.once("value", (snap) => {
        console.log("firebase realitme database");
        console.log(snap.val());
        initialLoadDone = true;
        if (snap.exists()) {

          var maindata = snap.val();
          var uids = Object.keys(snap.val());
          getchatuserlist(uids, maindata, (data) => {
            if (data.error === 0) {
              console.log("Getting user list for chat");
              console.log(data.data);
              console.log(maindata);
              // dispatch({
              //   type: "GET_CONTACTS",
              //   // contacts: data.data,
              //   chats: maindata
              // })
              var totalnonread = 0;
              data.data.forEach(element => {
                totalnonread = totalnonread + (element.chat.us ? element.chat.us : 0);
              });

              dispatch({
                type: "GET_CHAT_CONTACTS",
                chats: data.data,
                totalnonread: totalnonread
              })
              console.log({ contacts: data.data, chats: maindata });
              resolve();
            } else {
              console.log("There was an error");
            }

          })
        } else {
          console.log("Empty Chat");
          dispatch({
            type: "GET_CHAT_CONTACTS",
            chats: [],
            totalnonread: 0
          })
          resolve();
        }
      })
    })



    
  }
}

export const getContactChats = () => {
  return dispatch => {
    console.log("Getting contacts chats");
    // axios
    //   .get("api/app/chat/chat-contacts")
    //   .then(response => {
    //     console.log(response.data);
    //     dispatch({
    //       type: "GET_CHAT_CONTACTS",
    //       chats: response.data
    //     })
    //   })
    //   .catch(err => console.log(err))
  }
}

export const togglePinned = (id, value) => {
  return dispatch => {
    console.log("In toggle pinned");
    axios
      .post("/api/apps/chat/set-pinned/", {
        contactId: id,
        value
      })
      .then(response => {
        console.log(response);
        dispatch({
          type: "SET_PINNED",
          id,
          value
        })
      })
      .catch(err => console.log(err))
  }
}

export const sendMessage = (id, isPinned, text) => {
  if (text.length > 0) {
    return dispatch => {
      console.log("In send message");

      let newMsg = {
        textContent: text,
        isSent: true,
        isSeen: false,
        time: new Date().toString()
      }
      console.log(newMsg);
      axios
        .post("/api/app/chat/send-message", {
          contactId: id,
          message: newMsg,
          isPinned
        })
        .then(response => {
          dispatch({
            type: "SEND_MESSAGE",
            msg: newMsg,
            id,
            isPinned,
            text
          })
          dispatch(getChats())
        })
        .catch(err => console.log(err))
    }
  } else {
    return
  }
}

export const changeStatus = status => {
  return dispatch => {
    dispatch({
      type: "CHANGE_STATUS",
      status
    })
  }
}

export const searchContacts = query => {
  return dispatch => {
    dispatch({
      type: "SEARCH_CONTACTS",
      query
    })
  }
}

export const markSeenAllMessages = id => {
  console.log("In mark seen all message");
  return dispatch => {
    axios
      .post("/api/apps/chat/mark-all-seen/", {
        contactId: id
      })
      .then(response => {
        console.log(response.data);
        dispatch({
          type: "MARK_AS_SEEN",
          id
        })
      })
      .catch(err => console.log(err))
  }
}
